<template>
  <div class="box-page" style="padding-right: 15px">
    <div class="fiter">
      <div class="form-filter">
        <a-form ref="formRef" layout="horizontal" :model="formState">

          <a-row :gutter="24">
            <a-col :span="9">
              <a-form-item label="违规等级" name="risk" style="margin-bottom: 10px">
                <!--                //违规等级 1:特大违规;2:重大违规;3:一般违规;4:轻微违规;0:无违规;-->
                <a-radio-group v-model:value="formState.risk" button-style="solid" @change="handelSearch">
                  <a-radio-button :value="ruleTypeItem.value" v-for="ruleTypeItem in ruleTypeDescList"
                                  :key="ruleTypeItem.label">
                    <el-tooltip placement="bottom">
                      <template #content>
                        <div style="
                              padding: 0 5px;
                              font-size:12px;
                            ">
                          <span :style="{color: ruleTypeItem.labelColor}">{{ ruleTypeItem.label }}</span>
                          <span style="margin-left: 10px; float: right; color: #fff0f0">{{ ruleTypeItem.desc }}</span>
                        </div>
                      </template>
                      <p class="ellipsis">{{ ruleTypeItem.label }}</p>
                    </el-tooltip>
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-form-item label="是否命中" name="hitTypeStatus">
                <a-radio-group name="radioGroup" v-model:value="formState.hitTypeStatus">
                  <a-radio value="">全部</a-radio>
                  <a-radio value="1">命中</a-radio>
                  <a-radio value="0">未命中</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-form-item label="是否已读" name="read">
                <a-radio-group name="radioGroup" v-model:value="formState.read">
                  <a-radio :value="''">全部</a-radio>
                  <a-radio :value="0">未读</a-radio>
                  <a-radio :value="1">已读</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="7">
              <a-form-item label="审核结果" name="auditStatus">
                <a-radio-group name="radioGroup" v-model:value="formState.auditStatus">
                  <a-radio value="5">全部</a-radio>
                  <a-radio value="1">正常</a-radio>
                  <a-radio value="2">违规</a-radio>
                  <a-radio value="3">误判</a-radio>
                  <a-radio value="4">复审</a-radio>
                  <a-radio value="0">未审核</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>


          </a-row>

          <a-row :gutter="24" align="middle">
            <a-col :span="20">
              <a-row :gutter="24">

                <a-col :span="5">
                <a-form-item label="渠道用户" name="uid">
                <a-select
            
                  v-model:value="formState.uid"
                  placeholder="请选择用户名"
                  :filterOption="(input, option)=> { return option.label.indexOf(input) >= 0;}"
                  show-search allow-clear
                   
                >
                  <a-select-option
                    v-for="(item, index) in user_list"
                    :key="index"
                    :label="item.nickname"
                    :value="item.uid"
                  >
                
                 <div  style="width: 56px;" class="flex-nowrap-space-between">
                      <div :class="item.status == 1?'no-red':''">
                        <span>{{ item.nickname }}</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div> 
                  </a-select-option >
                </a-select>
              </a-form-item>
            </a-col>
                <a-col :span="5" v-if="$store.getters.userType == 'system'">
                  <a-form-item label="服务商" name="amountIdList" >
                    <a-select 
                    mode="multiple"
                    v-model:value="formState.amountIdList" placeholder="请选择服务商" show-search allow-clear
                              :options="amountOption"
                              :filterOption="(input, option)=> { return option.label.indexOf(input) >= 0;}"
                              ref="select">
                      <a-select-option :value="item.value" v-for="item in amountOption"
                                       :label="item.label"
                                       :key="item.value">{{
                          item.label
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="5" v-if="$store.getters.userType == 'web'">
                  <a-form-item label="服务商" name="amountIdList" >
                    <a-select 
               
                    v-model:value="formState.amountId" placeholder="请选择服务商" show-search allow-clear
                              :options="amountOption"
                              :filterOption="(input, option)=> { return option.label.indexOf(input) >= 0;}"
                              ref="select">
                      <a-select-option :value="item.value" v-for="item in amountOption"
                                       :label="item.label"
                                       :key="item.value">{{
                          item.label
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="5">
                  <a-form-item label="主叫号码" name="telA">
                    <a-input v-model:value="formState.telA" placeholder="请输入主叫号码"/>
                  </a-form-item>
                </a-col>
                <a-col :span="5">
                  <a-form-item label="关键词" name="translatedContent">
                    <a-input v-model:value="formState.translatedContent" placeholder="请输入关键词"/>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="创建时间" name="createTime">
                    <a-config-provider :locale="zhCN" >
                      <a-range-picker v-model:value="formState.createTime" :locale="zhCN"/>
                    </a-config-provider>
                  </a-form-item>
                </a-col>
                <a-col :span="6" v-if="$store.getters.userType == 'system'">
                  <a-form-item label="操作人" name="createTime">
                   <el-select  placeholder="请选择"  v-model="formState.sys1" clearable filterable size='small'>
                  <el-option v-for="(item, index) in  userQuerydata" :key="index" :label="item.nickname" :value="item.nickname">
                    <div class="flex-nowrap-space-between">
                      <div>
                        <span>{{ item.nickname }}</span>
                     
                      </div>
                   
                    </div>
                  </el-option>
                </el-select>
                  </a-form-item>
                </a-col>
                 <a-col :span="9" v-if="$store.getters.userType == 'system'">
                  <a-form-item label="操作时间" >
                     <a-config-provider :locale="zhCN">
                      <a-range-picker v-model:value="formState.createTime1" :locale="zhCN"/>
                    </a-config-provider>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="24" v-show="!isOutlined">
                <a-col :span="5" v-if="isVoiceOptionInit">
                  <a-form-item label="质检类型" name="hitType"> 
                     <el-select v-model="formState.hitType" placeholder="请选择质检类型" size='small' clearable>
                        <el-option
                         v-for="item in voiceOption"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                         >
                       </el-option>
                     </el-select>
                    <!-- <a-select v-model:value="formState.hitType"
                              placeholder="请选择质检类型"
                              :filterOption="(input, option)=> { return option.label.indexOf(input) >= 0;}"
                              show-search allow-clear>
                      <a-select-option :value="item.value" v-for="item in voiceOption"
                                       :label="item.label"
                                       :key="item.value">{{
                          item.label
                        }}
                      </a-select-option>
                    </a-select> -->
                  </a-form-item>
                </a-col>
                <a-col :span="5">
                  <a-form-item label="通话时间" name="duration">
                    <a-select v-model:value="formState.duration" placeholder="请选择通话时间">
                      <a-select-option v-for="item in [
                    {
                      name: '所有时间',
                      val: 0
                    }, {
                      name: '0~10秒',
                      val: 1
                    }, {
                      name: '10~60秒',
                      val: 2
                    }, {
                      name: '60~90秒',
                      val: 3
                    }, {
                      name: '90~120秒',
                      val: 4
                    }, {
                      name: '大于120秒',
                      val: 5
                    },
                  ]" :key="item.name" :label="item.name" :value="item.val">{{ item.name }}
                      </a-select-option>
                    </a-select>
                    <!-- <a-range-picker v-model:value="formState.duration" /> -->
                  </a-form-item>
                </a-col>
                <a-col :span="5">
                  <a-form-item label="企业号码" name="telX">
                    <a-input v-model:value="formState.telX" placeholder="请输入企业号码"/>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="被叫号码" name="telB">
                    <a-input v-model:value="formState.telB" placeholder="请输入被叫号码"/>
                  </a-form-item>
                </a-col>

              </a-row>
            </a-col>

            <a-col :span="4">
              <a-row>
                <a-col :span="24" :style="{ textAlign: 'right' }">
                  <a-button type="primary" @click="handelSearch"> 查询</a-button>
                  <a-button :style="{ marginLeft: '8px' }" @click="handelReset"> 重置</a-button>
                  <a-button :style="{ marginLeft: '8px' }" type="link" @click="switchOutlined">
                    {{ isOutlined ? '展开' : '收起' }}
                    <DownOutlined v-if="isOutlined"/>
                    <UpOutlined v-else/>
                  </a-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-form>

      </div>
    </div>
    <div class="table-list" style="padding-top: 5px">
      <el-table class="eltable" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark"
                style="width: 98%" :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }" row-key="id"
                size="mini"
                :row-style="{ height: '50px' }" :cell-style="{ padding: '5px 0px' , color: '#606266'}"
                default-expand-all
                :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" :row-class-name="tableRowClassName"
                @cell-click="handleRowClick">
        <el-table-column label="服务商" width="200">
          <template #default="{ row }">
            <el-row>
              <el-col>
                <span><b>服务商:</b> {{ getAmountName(row.amountId) }} </span>
                <br>
                <span v-if="!needHideKeyValue&&isAdminUser === 'system'"><b>渠道:</b> {{
                    row.nickName ? row.nickName : row.company
                  }}</span>
                <br>
                <span v-if="!needHideKeyValue"><b>企业:</b> {{ row.terminalName ? row.terminalName : '--' }}</span>
              </el-col>
              <el-col v-if="row.voiceTagsName&&row.voiceTagsName.length>0">
                <el-tag
                    size="small" effect="light" round v-for="(item, i) in row.voiceTagsName"
                    :key="i" :type="voiceTagType(i)" style="margin-right: 4px;margin-bottom: 2px">
                  {{ item }}
                </el-tag>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="呼叫号码(主叫/小号/被叫)" width="200">
          <template #default="{ row }">
            <div class="">
              <span><b>主叫:</b>{{ telADesc(row) }} <span :class="row.telADisabled ? 'default-color' : 'yes-green'">{{
                  row.telAName
                }}</span></span>
              <br>
              <span v-if="row.telAAttr"><b>归属地:</b>{{
                  row.telAAttr || ''
                }}</span>
            </div>
            <!--            <span><b>小号:</b> {{ row.telX }}</span>-->
            <!--            <br>-->
            <div class="">
              <span><b>被叫:</b>{{ telBDesc(row) }} <span :class="row.telBDisabled ? 'default-color' : 'yes-green'">{{
                  row.telBName
                }}</span></span>
              <br>
              <span v-if="row.telBAttr"><b>归属地:</b>{{
                  row.telBAttr || ''
                }}</span>
            </div>

            <!--            <span><b>被叫:</b> {{ row.telB }} <span :class="row.telBDisabled ? 'default-color' : 'yes-green'">{{-->
            <!--                row.telBName-->
            <!--              }}</span>-->
            <!--            </span>-->
            <!--            <span >{{-->
            <!--                row.telBAttr||'上海 虹桥 移动'-->
            <!--              }}</span>-->
          </template>
        </el-table-column>
        <el-table-column label="通话时长" width="80" prop="duration">
        </el-table-column>
        <el-table-column label="违规等级" width="80" prop="risk">
          <template #default="{ row }">
            <div
                :class="rowRuleTypeClass(row)"
                style="width: 60px;height: 26px;border-radius: 4px;text-align: center;margin: auto;line-height: 26px">
              {{ rowRuleTypeText(row) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="关键词组/关键词" width="180">
          <template #default="{ row }">
            <el-tooltip placement="top">
              <template #content>
                <p class="ellipsis">
                  <b>准入行业:</b>
                  {{
                    rowIndustry(row)
                  }}
                </p>
                <p class="ellipsis">
                  <b>类型:</b> {{ row.hitType == null || row.hitType == '' ? '-' : row.hitType }}
                </p>
                <p class="ellipsis">
                  <b>关键字:</b>
                  {{ row.hitTypeDesc == null || row.hitTypeDesc == '' ? '-' : row.hitTypeDesc }}
                </p>
              </template>
              <div>
                <p class="ellipsis" style="margin-bottom: 5px">
                  <b>准入行业:</b> {{
                    rowIndustry(row)
                  }}
                </p>
                <p class="ellipsis" style="margin-bottom: 5px">
                  <b>类型:</b> {{ row.hitType == null || row.hitType == '' ? '-' : row.hitType }}
                </p>
                <p class="ellipsis" style="margin-bottom: 5px">
                  <b>关键字:</b>
                  {{ row.hitTypeDesc == null || row.hitTypeDesc == '' ? '-' : row.hitTypeDesc }}
                </p>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <!--        <el-table-column label="质检类型" min-width="100" prop="hitType">-->
        <!--        </el-table-column>-->
        <!--        <el-table-column label="敏感词" min-width="100" prop="hitTypeDesc">-->
        <!--        </el-table-column>-->
        <!-- <el-table-column label="报备行业" width="80" prop="date">
        </el-table-column> -->


        <el-table-column label="话术/语音内容" :width="680">
          <template #default="{ row }">
            <!--            <el-popover placement="left" :width="680" trigger="hover" effect="dark">-->
            <!--              <template #reference>-->
            <!--                <p class="ellipsis-line3" v-html="row.translatedContentSentenceTextList.join('')"/>-->
            <!--              </template>-->
            <!--              <template #default>-->
            <!--                <el-row class="voice-text">-->
            <!--                  <el-col :span="8" v-if="row.hitTypeDesc && row.hitTypeDesc !== '-'">-->
            <!--                    <el-tag size="small" effect="light" round v-for="(item, i) in row.hitTypeDesc.split(',')"-->
            <!--                            :key="i" class="keywords" @click="scrollTo($event)">-->
            <!--                      {{ item }}-->
            <!--                    </el-tag>-->
            <!--                  </el-col>-->
            <!--                  <el-col :span="16">-->
            <!--                    <el-scrollbar class="voice-text-tooltip" max-height="300px">-->
            <!--                      <p v-for="(item, i) in row.translatedContentSentenceTextList" :key="i">-->
            <!--                        <span class="sentence" v-html="item"/>-->
            <!--                      </p>-->
            <!--                    </el-scrollbar>-->
            <!--                  </el-col>-->
            <!--                </el-row>-->
            <!--              </template>-->
            <!--            </el-popover>-->
            <p v-if="row.translatedContentSentenceTextList"
               class="ellipsis-line3"
               v-html="row.translatedContentSentenceTextList.join('')"/>
            <p v-else>-</p>
          </template>
        </el-table-column>

        <el-table-column label="审核状态/日期" width="180" prop="auditStatus">
          <template #default="{ row }">
            <el-tooltip placement="top">
              <template #content>
                <p class="ellipsis">
                  <b>审核状态:</b>{{ rowAuditStatusDesc(row) }}
                </p>
                <p class="ellipsis">
                  <b>质检日期:</b> {{ row.createTime || '--' }}
                </p>
              </template>
              <div>
                <span class="ellipsis" :class="rowAuditStatusClass(row)"
                      style="margin-right: 10px;padding-left: 10px;padding-right: 10px;padding-top: 5px;padding-bottom: 5px">
                  {{ rowAuditStatusDesc(row) }}
                </span>
                <p class="ellipsis">
                  {{ row.createTime || '--' }}
                </p>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作人/操作时间" width="180" v-if="$store.getters.userType == 'system'">
          <template #default="{ row }">
            <el-tooltip placement="top">
              <template #content>
                <p class="ellipsis">
                  <b>操作人:</b>{{ row.processor || '--' }}
                </p>
                <p class="ellipsis">
                  <b>质检日期:</b> {{ row.processingTime || '--' }}
                </p>
              </template>
              <div>
                <p class="ellipsis">
                  {{ row.processor || '--' }}
                </p>
                <p class="ellipsis">
                  {{ row.processingTime || '--' }}
                </p>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <!--        <el-table-column label="质检日期" width="100" fixed="right" prop="createTime">-->
        <!--        </el-table-column>-->
        <!--        <el-table-column label="操作时间" width="100" fixed="right" prop="processingTime">-->
        <!--        </el-table-column>-->
        <!--        <el-table-column label="操作人" width="80" fixed="right" prop="processor">-->
        <!--        </el-table-column>-->
        <el-table-column label="处理办法" width="200" fixed="right" v-if="$store.getters.userType == 'system'">
          <template #default="{ row }">
            <div class="m3-flex">
              <!-- <a-button @click="handelRowProcess(row)">操作</a-button> -->
              <a-button v-if="isAdminUser == 'system'" @click="addWorkOrder(row)" class="m3-margin-left-s">添加工单
              </a-button>
            </div>

          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pager" style="padding-top: 8px">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="pageForm.currentPage" :page-size="pageForm.pageSize" background
                     :page-sizes="[10, 20, 30, 40]"
                     layout="total, sizes, prev, pager, next, jumper" :total="pageForm.total">
      </el-pagination>
    </div>

    <WorkOrderRecordDialog
        v-model:dialog-visible="workOrderDialogVisible"
        :record="currentWorkOrder"
        @confirm="workOrderDialogConfirm"/>

    <recordDialog
        @successDDD="handelSuccess" :isAdminUser="isAdminUser" :amount-option="amountOption"
        @nextData="handleRowClick" ref="record" v-model="recordVisible" :rowJson="rowJson"
        type="apiRule" :show-add-work-order="true" @add-work-order="addWorkOrder(null)"
        @success="recordVisible = false"/>

    <process-dialog width="480px" v-model:show="processVisible"
                    :row-json="rowJson"
                    @confirm="processDialogConfirm">
    </process-dialog>

  </div>
</template>
<script>
import userApp from '@/api/web/userApp'
import userts from "@/api/web/user";
import {industryQuery} from '@/api/open/industry/query'
import {DownOutlined, UpOutlined} from '@ant-design/icons-vue'
import {reactive, ref} from 'vue'
import _lineService from '@/api/open/LineQualityInspections'
import moment from 'moment'
import recordDialog from './record-dialog.vue'
import {PageModule} from '../common/pagination'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import voiceCheck from "@/api/open/voiceCheck/voiceCheck";
import {getList} from "@/api/open/privacy/number";
import deepClone from "@/utils/deepClone";
import Cookies from "js-cookie";
import ProcessDialog from "@/views/open/LineQualityInspections/process-dialog.vue";
import WorkOrderRecordDialog from "@/views/open/workOrder/RecordDialog.vue";
import {httpEnv} from "@/utils/config";

export default {
  components: {
    ProcessDialog,
    DownOutlined,
    UpOutlined,
    recordDialog,
    WorkOrderRecordDialog,
  },
  props: {
    voiceOption: {
      type: Array,
      default: () => [],
    },
    amountOption: {
      type: Array,
      default: () => [],
    },
    isAdminUser: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      userQuerydata:[],
      user_list: [],
      industryList: [],
      tableData: [],
      formState: reactive(new FormModel()),
      isOutlined: true,
      isVoiceOptionInit: false,
      loading: false,
      pageForm: new PageModule(),
      recordVisible: false,
      processVisible: false,
      workOrderDialogVisible: false,
      currentWorkOrder: {},
      rowJson: {
        translatedContent: [],
      },
      ruleTypeDescList: [
        {label: '全部', value: '', desc: '0/0', labelColor: '#fff0f0', rowClass: ''},
        {label: '特大违规', value: 1, desc: '0/0', labelColor: '#fa5151', rowClass: 'rule-type-highest'},
        {label: '重大违规', value: 2, desc: '0/0', labelColor: '#ff8f1f', rowClass: 'rule-type-high'},
        {label: '一般违规', value: 3, desc: '0/0', labelColor: '#ffc300', rowClass: 'rule-type-mid'},
        {label: '轻微违规', value: 4, desc: '0/0', labelColor: '#96b97d', rowClass: 'rule-type-low'},
        // {label: '无违规', value: 0, desc: '0/0', labelColor: '#00b578', rowClass: 'rule-type-none'},
      ],
      progressData: [
        // {label: '高', value: 3},
        // {label: '中', value: 2},
        // {label: '低', value: 1},
        // {label: '无', value: 0},
        {label: '全部', value: '', desc: '0/0', labelColor: '#fff0f0'},
        {label: '高', value: 3, desc: '0/0', labelColor: '#fa5151'},
        {label: '中', value: 2, desc: '0/0', labelColor: '#ff8f1f'},
        {label: '低', value: 1, desc: '0/0', labelColor: '#ffc300'},
        {label: '无', value: 0, desc: '0/0', labelColor: '#00b578'},
      ],

      // auditStatusClass() {
      //   if (this.rowJson.auditStatus == '1') {
      //     return 'audit-status-none'
      //   } else if (this.rowJson.auditStatus == '2') {
      //     return 'audit-status-highest'
      //   } else if (this.rowJson.auditStatus == '3') {
      //     return 'audit-status-high'
      //   } else if (this.rowJson.auditStatus == '4') {
      //     return 'audit-status-mid'
      //   } else if (this.rowJson.auditStatus == '0') {
      //     return 'audit-status-low'
      //   }
      //   return 'audit-status-low'
      // },
      auditStatusDescs: [
        {label: '未审核', value: 0, rowClass: 'audit-status-low'},
        {label: '已审核', value: 1, rowClass: 'audit-status-none'},
        {label: '违规', value: 2, rowClass: 'audit-status-highest'},
        {label: '误判', value: 3, rowClass: 'audit-status-high'},
        {label: '复审', value: 4, rowClass: 'audit-status-mid'},
      ],
      voiceTagTypes: ['success', 'success', 'success', 'success','success'],
      zhCN: zhCN,
    }
  },
  async created() {
    await this.initData();
    this.getUserList()
  },
  mounted() {
    industryQuery({}).then((res) => {
      let {code, data, message} = res
      if (code == 200) {
        this.industryList = data
      }
    })
    if (this.$store.getters.userType === 'system') {
         this.getuserQuery() 
    }
   
  },
  watch: {
    async isAdminUser(newV, preV) {
      // console.log('record isAdminUser',newV,preV);
      if (newV && newV != preV) {
        await this.initData();
      }
    }
  },
  computed: {
    needHideKeyValueRoleId() {
      let env = httpEnv();
      if (env == 'dev') {
        return 7;
      } else if (env == 'test') {
        return 7;
      }
      return 16;
    },
    needHideKeyValue() {
      //部分关键值不能被看到（目前对部分兼职生效）：渠道隐藏、企业隐藏、主叫脱敏、被叫脱敏
      return this.$store.state.user?.roleId == this.needHideKeyValueRoleId;
      // return true;
    },
    telADesc() {
      return row => {
        // if (this.needHideKeyValue) {
        //   return row.telA && row.telA.length >= 11 ? row.telA.replace(row.telA.substring(3, 7), "****") : row.telA;
        // }
        return row.telA;
      }
    },
    telBDesc() {
      return row => {
        // if (this.needHideKeyValue) {
        //   return row.telB && row.telB.length >= 11 ? row.telB.replace(row.telB.substring(3, 7), "****") : row.telB;
        // }
        return row.telB;
      }
    },
    voiceTagType() {
      return index => {
        if (index < this.voiceTagTypes.length - 1) {
          return this.voiceTagTypes[index];
        }
        return this.voiceTagTypes[index % this.voiceTagTypes.length];
      }
    },
    rowIndustry() {
      return (row) => {
        if (row?.businessType != null && this.industryList?.length != 0) {
          let find = this.industryList.find((item) => item.id == row.businessType);
          row.industry = find?.name || '--';
        } else {
          row.industry = '--'
        }
        return row.industry;
      }
    },
    rowRuleTypeText() {
      return (row) => {
        let find = this.ruleTypeDescList.find(item => item.value == row.risk);
        if (find?.label == '全部') {
          return '--';
        }
        return find?.label || '';
      }
    },
    rowRuleTypeClass() {
      return (row) => {
        let find = this.ruleTypeDescList.find(item => item.value == row.risk);
        return find?.rowClass || '';
      }
    },
    rowAuditStatusDesc() {
      return (row) => {
        let find = this.auditStatusDescs.find(item => item.value == row.auditStatus);
        return find?.label || '--';
      }
    },
    rowAuditStatusClass() {
      return (row) => {
        let find = this.auditStatusDescs.find(item => item.value == row.auditStatus);
        return find?.rowClass || '--';
      }
    }
  },
  methods: {
     getuserQuery() {
      userApp
        .userQuery({
        })
        .then((res) => {
          this.userQuerydata = res.data

        })

    },
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data;
      });
    },
    getList,
    async initData() {
      if (this.isAdminUser) {
        if (this.isAdminUser === 'system') {
          await this.getAdminCheckList()
        } else {
          await this.getUserCheckList()
        }
        await this.getRuleTypeList()
      }
    },
    // 滚动到指定质检关键字
    // 暂未实现同个关键字多处位置间的切换
    scrollTo(e) {
      this.$nextTick(() => {
        let list =
            e.currentTarget.parentElement.nextElementSibling.querySelectorAll(
                '.keyword-tag'
            )
        for (let i = 0; i < list.length; i++) {
          if (list[i].innerText === e.currentTarget.innerText) {
            list[i].scrollIntoView()
          }
        }
      })
    },
    // 将一条质检记录标记为已读(暂未刷新统计数)
    quickConfirm(row) {
      if (row.read == 1) {
        return
      }
      {
        voiceCheck
            .esCheck({
              id: row.id,
            })
            .then((res) => {
              if (res.code == 200) {
                row.read = 1
              }
            })
      }
    },
    tableRowClassName({row}) {
      // console.log(row)
      if (row.read === 1 || row.auditStatus === 1) {
        // return 'green-row'
        return 'el-tab-row-primary-light'
      } else if (row.auditStatus === 2) {
        return 'el-tab-row-error'
      } else if (row.auditStatus === 3) {
        return 'el-tab-row-warning'
      } else if (row.auditStatus === 4) {
        return 'el-tab-row-warning'
      }
      return ''
    },
    updateTableData(id, values) {
      //更新属性值
      this.$nextTick(() => {
        let findLine = this.$refs.multipleTable.data.find(
            (item) => item.id == id
        )
        if (findLine) {
          for (let prop in values) {
            findLine[prop] = deepClone(values[prop]);
          }
        }
      })
    },
    async handelRowProcess(row) {
      this.rowJson = row
      this.processVisible = true
    },
    async addWorkOrder(row) {
      // this.rowJson = row
      let item = row || this.rowJson;
      this.currentWorkOrder = {
        amountId: item?.amountId,
        amountName: this.getAmountName(item?.amountId),
        status: 0,
        checkId: item?.id,
        orderFrom: 0,//0-平台质检 1-运营商质检
        caller: item?.telA,//主叫
        callee: item?.telB,//被叫
        telX: item?.telX,//小号
      }
      this.workOrderDialogVisible = true
    },
    async workOrderDialogConfirm() {
      await this.initData();
    },
    async processDialogConfirm(result) {
      if (result) {
        this.updateTableData(this.rowJson?.id, result);
      }
    },
    async getNextTableData(index) {
      let res;
      if (index != 0) {
        //下一个
        let findIndex = this.tableData?.findIndex(item => item.id == this.rowJson.id);
        // console.log('getNextTableData aaa', findIndex, this.tableData.length,)
        if (findIndex !== -1) {
          let nextIndex = findIndex + index;
          if (nextIndex >= 0 && nextIndex <= this.tableData.length - 1) {
            res = this.tableData[nextIndex];
          } else if (index < 0 && nextIndex < 0 && this.pageForm.currentPage > 1) {
            //加载上一页
            await this.handleCurrentChange(this.pageForm.currentPage - 1);
            res = this.tableData[this.tableData.length - 1];
          } else if (index > 0 && nextIndex > this.tableData.length - 1) {
            //加载下一页
            await this.handleCurrentChange(this.pageForm.currentPage + 1);
            res = this.tableData[0];
          }
        }
      } else {
        //当前行
        res = this.tableData?.find(item => item.id == this.rowJson.id);
      }
      return res;
    },
    async handleRowClick(val, column, cell, event, values) {
      if (column?.label == '处理办法') {
        return;
      }
       console.log('handleRowClick', val, column, cell, event, values)
      if (values) {
        //更新当前行数据
        let current = await this.getNextTableData(0);
        if (current) {
          this.updateTableData(current.id, values);
        }
      }
      if (val == 1 || val == -1) {
        val = await this.getNextTableData(val);
      }
      // console.log('handleRowClick get rowData', val, column, cell, event, values)
      if (!val) {
        //最后一页最后一条，没有更多了
        return;
      }
      if (!val.read) {
        if (this.isAdminUser === 'system') {
          await _lineService._recordService.getSMMRead({id: val.id})
        } else {
          await _lineService._recordService.getRead({id: val.id})
        }
      }

      val.amountName = this.getAmountName(val.amountId);//服务商名称，方便弹窗获取
      this.rowJson = val
      this.recordVisible = true
      if (!val.read) {
        this.updateTableData(val.id, {read: 1});
      }

      this.$nextTick(() => {
        this.$refs.record.open()
      })
    },
    async handleSizeChange(val) {
      this.pageForm.setPageSize(val)
      await this.initData();
    },
    async handleCurrentChange(val) {
      this.pageForm.setCurrentPage(val)
      await this.initData();
    },
    getParams() {
      return {
        ...this.pageForm.getParamsJson(),
        ...this.formState.getParamsJson(),
      }
    },
    async getUserCheckList() {
      this.loading = true;
      const res = await _lineService._recordService.getUserCheckList(
          this.getParams()
      )
      this.tableData = res.records.map((item) => new TableModel(item))
      this.pageForm.setTotal(res.total)
      this.loading = false;
    },
    async getAdminCheckList() {
      this.loading = true;
      const res = await _lineService._recordService.getCheckList(
          this.getParams()
      )
      this.tableData = res.records.map((item) => {
        return new TableModel(item)
      })
      this.pageForm.setTotal(res.total)
      this.loading = false;
    },
    getAmountName(id) {
      const obj = this.amountOption.find((item) => item.value == id)
      const name = obj ? obj.label : '--'
      return !!id ? name : '--'
    },
    async getRuleTypeList() {
      let res
      if (this.isAdminUser === 'web') {
        res = await _lineService._recordService.getProgressChart(
            this.formState.getCreateTime()
        )
      } else {
        res = await _lineService._recordService.getSystemProgressChart(
            this.formState.getCreateTime()
        )
      }

      this.ruleTypeDescList.forEach(ruleTypeItem => {
        let read = 0, total = 0;
        res?.data?.forEach(item => {
          if (ruleTypeItem.value == item.risk) {
            read = item?.read || 0;
            total = item?.total || 0;
          }
        })
        ruleTypeItem.read = read;
        ruleTypeItem.total = total;
        ruleTypeItem.desc = `${read}/${total}`;
      })
      let all = this.ruleTypeDescList.find(item => !item.value);
      if (all) {
        let read = this.ruleTypeDescList.filter(item => item.value).reduce((sum, e) => sum + Number(e.read || 0), 0);
        let total = this.ruleTypeDescList.filter(item => item.value).reduce((sum, e) => sum + Number(e.total || 0), 0);
        all.read = read || 0;
        all.total = total || 0;
        all.desc = `${read}/${total}`;
      }
    },
    async voiceCheckProgressChart() {
      const res = await _lineService._recordService.voiceCheckProgressChart()
    },
    async handelSearch() {
      console.log(this.formState.createTime,this.formState.createTime1);
      this.pageForm.init()
      await this.initData();
    },
    async handelSuccess() {
      this.recordVisible = false
      await this.initData();
    },
    handelReset() {
    this.$refs.formRef.resetFields()
    this.formState.sys1=''
     this.formState.createTime1 = [
      '',
     '',
    ]
    this.formState.amountId=''
      this.handelSearch()
    },
    switchOutlined() {
      this.isOutlined = !this.isOutlined;
      if (!this.isVoiceOptionInit) {
        this.isVoiceOptionInit = true;
      }
    }
  },
}


const date = new Date()
// const dateBefore = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
const str = `${date.getFullYear()}-${(date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)}-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`
// const strBefore = `${dateBefore.getFullYear()}-${(dateBefore.getMonth() + 1) > 9 ? (dateBefore.getMonth() + 1) : '0' + (dateBefore.getMonth() + 1)}-${dateBefore.getDate() > 9 ? dateBefore.getDate() : '0' + dateBefore.getDate()}`


/*
  主叫号码 telA 企业号码 telX 被叫号码 telB
  创建时间 createTimeBegin createTimeEnd 风险等级 risk
  命中关键词 keyword 质检类型 hitType
  用户 uid 服务商 amountId 接口类型 interfaceId
  通话时间 durationBegin durationEnd
*/
class FormModel {
  constructor() {
    this.createTime1 = [
     '',
      '',
    ]
    this.sys1=''
    this.translatedContent = ''
    this.telA = ''
    this.telX = ''
    this.telB = ''
    this.createTime = [
      moment(str+'00:00:00', 'YYYY-MM-DD  HH:mm:ss'),
      moment(str+'23:59:59', 'YYYY-MM-DD  HH:mm:ss'),
    ]
    this.type = ''//违规等级 1:特大违规;2:重大违规;3:一般违规;4:轻微违规;0:无违规;
    this.risk = 1//违规等级（代替原风险等级）1:特大违规;2:重大违规;3:一般违规;4:轻微违规;0:无违规;
    this.keyword = ''
    this.hitType = null
    this.read = ''
    this.uid = null
    this.amountId = ''
    this.amountIdList = []
    this.interfaceId = ''
    this.duration = undefined
    this.fieldA = ''
    this.hitTypeStatus = ''
    this.auditStatus = '5'
  }

  getParamsJson() {
    return {
      translatedContent: this.translatedContent,
      telA: this.telA,
      telX: this.telX,
      telB: this.telB,
      createTimeBegin:
          this.createTime && this.createTime[0]
              ? this.createTime[0].format('YYYY-MM-DD HH:mm:ss')
              : '',
      createTimeEnd:
          this.createTime && this.createTime[1]
              ? this.createTime[1].format('YYYY-MM-DD HH:mm:ss')
              : '',
      processingTimeBegin:
          this.createTime1 && this.createTime1[0]
              ? this.createTime1[0].format('YYYY-MM-DD')
              : '',
      processingTimeEnd:
          this.createTime1 && this.createTime1[1]
              ? this.createTime1[1].format('YYYY-MM-DD')
              : '',
      // type: this.type,
      risk: this.risk,
      keyword: this.keyword,
      hitType: this.hitType,
      read: this.read,
      uid: this.uid,
      amountId: this.amountId,
      amountIdList:this.amountIdList,
      interfaceId: this.interfaceId,
      processor:this.sys1,
      durationBegin: this.duration ? this.durationToVal(this.duration)[0] : '',
      durationEnd: this.duration ? this.durationToVal(this.duration)[1] : '',
      hitTypeStatus: this.hitTypeStatus,
      auditStatus: this.auditStatus === '5' ? '' : this.auditStatus,
    }
  }

  durationToVal(val) {
    if (val == 0) {
      return []
    }
    if (val == 1) {
      return [0, 10]
    }
    if (val == 2) {
      return [10, 60]
    }
    if (val == 3) {
      return [60, 90]
    }
    if (val == 4) {
      return [90, 120]
    }
    if (val == 5) {
      return [120, '']
    }
  }

  getCreateTime() {
    return {
      dateBegin:
          this.createTime && this.createTime[0]
              ? this.createTime[0].format('YYYY-MM-DD HH:mm:ss')
              : '',
      dateEnd:
          this.createTime && this.createTime[1]
              ? this.createTime[1].format('YYYY-MM-DD HH:mm:ss')
              : '',
    }
  }
}

/*
  id 主键id uid 用户id interfaceId 线路id amountId 服务商id
  nickName 用户昵称 company 公司名 bindId 绑定id
  checkId 系统质检id callId 上游callId recordUrl ？
  duration 通话秒数 minutes 通话分钟数 telA 主叫
  telX 小号 telB 被叫 translatedResult 命中参数
  translatedContent ？ translatedHighlight
  hitType ？ hitTypeDesc ？ hitTypeStatus ？
  hitRule ？ hitRuleDesc ？ hitRuleLevel ？
  hitRuleStatus ？ createTime 创建时间
  updateTime 更新时间 businessType 行业id readRecord ？
  remark 备注 risk ？ auditStatus 审核状态 tags ？
  read 1:已读 0:未读 telADisabled ？ telBDisabled ？
  readInfor ？ stratTime 开始时间
*/
class TableModel {
  constructor(item) {
    this.sys1=item.sys1
    this.id = item.id
    this.uid = item.uid
    this.interfaceId = item.interfaceId
    this.amountId = item.amountId
    this.nickName = item.nickName
    this.company = item.company
    this.bindId = item.bindId
    this.checkId = item.checkId
    this.callId = item.callId
    this.recordUrl = item.recordUrl
    this.duration = item.duration ? item.duration + '秒' : '--'
    this.minutes = item.minutes
    this.telA = item.telA || '--'//主叫
    this.telX = item.telX || '--'//小号、中间号
    this.telB = item.telB || '--'//被叫
    this.telAAttr = item.telAAttr || ''//主叫归属地
    this.telBAttr = item.telBAttr || ''//被叫归属地
    this.translatedResult = item.translatedResult
    this.translatedContent = item.translatedContent
    // this.translatedContent = this.getVoiceText(item)
    this.translatedContentSentenceTextList = this.getVoiceText(item);//translatedContent变种，用于界面展示
    // this.desc = this.getVoiceText(item)
    this.translatedHighlight = item.translatedHighlight
    this.hitType = item.hitType
    this.hitTypeDesc = this.resetHitTypeDesc(item.hitTypeDesc)
    this.hitTypeStatus = item.hitTypeStatus
    this.hitRule = item.hitRule
    this.hitRuleDesc = item.hitRuleDesc
    this.hitRuleLevel = item.hitRuleLevel
    this.hitRuleStatus = item.hitRuleStatus
    this.createTime = item.createTime
    this.createTime1 = item.createTime1
    this.updateTime = item.updateTime
    this.businessType = item.businessType
    this.readRecord = item.readRecord
    this.remark = item.remark
    this.risk = item.risk
    this.riskText = this.setRiskText(item.risk)
    this.auditStatus = item.auditStatus
    this.auditName = this.setAuditName(item.auditStatus)
    this.tags = item.tags
    this.read = item.read
    this.telADisabled = item.telADisabled
    this.telXDisabled = item.telXDisabled
    this.telBDisabled = item.telBDisabled
    this.telAName = item.telADisabled ? '已禁用' : '正常'
    this.telBName = item.telBDisabled ? '已禁用' : '正常'
    this.readInfor = item.readInfor
    this.processor = item.processor //处理人
    this.processingImages = item.processingImages //处理图片集合，后端返回string类型，用英文逗号分割
    this.processingFiles = item.processingFiles //处理文件集合，后端返回string类型，用英文逗号分割
    this.processingMethod = item.processingMethod //处理方法
    this.processingTime = item.processingTime //处理时间
    this.terminalName = item.terminalName //终端企业名称
    this.voiceTagsName = item.voiceTagsName  //质检标签 例：['新用户','坐席多','长时']
    // this.voiceTagsName = ['新用户', '坐席多', '长时']  //质检标签 例：['新用户','坐席多','长时']
    this.stratTime = item.stratTime
  }

  setRiskText(val) {
    const obj = {
      0: '无',
      1: '低',
      2: '中',
      3: '高',
    }
    return obj[val] ? obj[val] : '--'
  }

  //审核状态(0-未审核/1-已审核/2-违规/3-误判/4-复审)(必传)
  setAuditName(val) {
    const obj = {
      0: '未审核',
      1: '已审核',
      2: '违规',
      3: '误判',
      4: '复审',
    }
    return obj[val] ? obj[val] : '--'
  }

  //如果有组合关键词，将组合词分割成一个个关键词
  resetHitTypeDesc(hitTypeDesc) {
    if (hitTypeDesc) {
      let hitTypeDescList = hitTypeDesc.split(',')
      if (hitTypeDescList) {
        let hasNo = hitTypeDescList.filter(item => item && item.indexOf('|') === -1);
        let has = hitTypeDescList.filter(item => item && item.indexOf('|') !== -1);//组合关键字需要分割成一个个的关键词
        let comb = [];
        if (has && has.length > 0) {
          has.forEach(item => {
            let splitItems = item.split('|');
            comb = comb.concat(splitItems);
          })
        }
        return hasNo.concat(comb).join(',');
      }
    }
    return hitTypeDesc;
  }

  // 根据后端返回的语音文本json,获取其中的句子部分,并高亮显示关键字
  getVoiceText(item) {
    let keywords = item.hitTypeDesc
    if (item.translatedContent == null) {
      return ['-']
    }
    let sentences = []
    try {
      sentences = JSON.parse(item.translatedContent)?.payload?.sentences
    } catch (err) {
      // 因查看对话会改变translatedContent值,这里先通过捕获异常处理
      sentences = item.translatedContent
    }
    if (keywords !== '' && keywords != null) {
      // 如果包含关键字,对关键字做高亮处理
      keywords.split(',').forEach((keyword) => {
        sentences?.map((sentence) => {
          if (sentence.text.search(keyword) !== -1) {
            sentence.text = sentence.text.replace(
                keyword,
                '<b class="keyword-tag" style="color:#ff0000;">' +
                keyword +
                '</b>'
            )
            return sentence
          }
        })
      })
    }
    if (sentences == undefined || sentences.length === 0) {
      return ['-']
    }
    return sentences.map((sentence) => sentence.text)
  }

  getTranslatedContent(val) {
    const obj = JSON.parse(val)
    if (obj && obj.payload && obj.payload.sentences) {
      const getKeyword = (keyword) =>
          `<b class="keyword-tag" style="color:#ff0000;">${keyword}</b>`
      if (!!this.hitTypeDesc) {
        this.hitTypeDesc.split(',').map((item) => {
          obj.payload.sentences.forEach(
              (item) =>
                  (item.text = replace(item.keyword, getKeyword(item.keyword)))
          )
        })
      }
      return obj.payload.sentences.map((item) => item.text)
    }
    return ['-']
  }
}

</script>

<style scoped lang="less">

.box-page /deep/ span.ant-radio + * {
  padding-right: 6px;
  padding-left: 6px;
}

.box-page /deep/ .ant-radio-wrapper {
  margin-right: 0px;
}

.box-page /deep/ .ant-form-item-label > label {
  width: 70px;
}

.box-page /deep/ .el-table {
  color: #333;
}

.box-page /deep/ .el-table .cell p {
  overflow: hidden;
  white-space: nowrap; //禁止换行
  text-overflow: ellipsis; //...
}

.riskText {
  width: 40px;
  height: 20px;
  line-height: 18px;
  border-radius: 15%;
  text-align: center;
}

.riskText-high {
  border: 1px solid #ff4949;
  color: #ff4949;
}

.riskText-middle {
  border: 1px solid #ffb352;
  color: #ffb352;
}

.riskText-low {
  border: 1px solid #eae556;
  color: #eae556;
}

.riskText-nothing {
  border: 1px solid #6be598;
  color: #6be598;
}

.rule-type-highest {
  border-radius: 4px;
  background: #fa5151;
  color: #FFFFFF;
}

.rule-type-high {
  border-radius: 4px;
  background: #ff8f1f;
  color: #FFFFFF;
}

.rule-type-mid {
  border-radius: 4px;
  background: #ffc300;
  color: #FFFFFF;
}

.rule-type-low {
  border-radius: 4px;
  background: #96b97d;
  color: #FFFFFF;
}

.rule-type-none {
  border-radius: 4px;
  background: #00b578;
  color: #FFFFFF;
}

.ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.ellipsis-line3 {
  white-space: normal !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  cursor: pointer;
}

.voice-text {
  .voice-text-tooltip {
    font-size: 0.75rem;
  }

  .keywords {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }
}

.sentence {
  background-color: #95ec69;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 7px;
  color: #000000;
}

//<a-radio :value="1">正常</a-radio>
//<a-radio :value="2">违规</a-radio>
//<a-radio :value="3">误判</a-radio>
//<a-radio :value="4">复审</a-radio>
//<a-radio :value="0">未审核</a-radio>

.audit-status-highest {
  border-radius: 4px;
  background: #fa5151;
  color: #FFFFFF;
}

.audit-status-high {
  border-radius: 4px;
  background: #ff8f1f;
  color: #FFFFFF;
}

.audit-status-mid {
  border-radius: 4px;
  background: #ffc300;
  color: #FFFFFF;
}

.audit-status-low {
  border-radius: 4px;
  background: #96b97d;
  color: #FFFFFF;
}

.audit-status-none {
  border-radius: 4px;
  background: #00b578;
  color: #FFFFFF;
}

.eltable :deep(.el-table__body) {
  .el-tab-row-error {
    background: #fcbebe;
  }

  .el-tab-row-warning {
    background: #f3dd94;
  }

  .el-tab-row-success {
    background: #b2edd9;
  }

  .el-tab-row-primary-light {
    background: #F0F5FF;
  }
}
</style>